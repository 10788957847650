// extracted by mini-css-extract-plugin
export var ContentBlock = "ContentBlock-module--ContentBlock--7knQN";
export var agb = "ContentBlock-module--agb--u3ALI";
export var col = "ContentBlock-module--col--kXTJe";
export var content = "ContentBlock-module--content--ti+cQ";
export var dataprivacy = "ContentBlock-module--dataprivacy--kMLdu";
export var datenschutz = "ContentBlock-module--datenschutz--Gvnjf";
export var finanzierung = "ContentBlock-module--finanzierung--ozq5x";
export var image = "ContentBlock-module--image--vvm77";
export var impressum = "ContentBlock-module--impressum--rRBGW";
export var teaser = "ContentBlock-module--teaser--H3mGR";
export var title = "ContentBlock-module--title--wglOI";
export var unternehmen = "ContentBlock-module--unternehmen--7hlE8";