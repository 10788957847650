import React from 'react';
import clsx from 'clsx';

import * as styles from './ContentBlock.module.css';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PageTitle } from './PageTitle';
import { Icon } from './Icon';

ContentBlock.propTypes = {};
ContentBlock.defaultProps = {};

function ContentBlock({ data, cmsUrl }) {
  const { title, icon, cssclass, teaser, text, image } = data;

  const contentClassNames = clsx(styles.content, styles[cssclass]);
  // TODO: it would be much better if the CMS Adapter would take care
  // of this; but it is not currently supported.
  let modifiedcontent =
    text && text.split('src="/').join(`src="${cmsUrl}`).split('class="gaOptOut"').join('href="javascript:gaOptout()"');

  return (
    <article className={clsx(styles.ContentBlock, 'defaultWidth')}>
      <PageTitle>
        {icon && <Icon icon={icon} />}
        {title}
      </PageTitle>
      {image && (
        <div>
          <GatsbyImage image={getImage(image)} className={styles.image} />
        </div>
      )}
      {teaser && <div className={styles.teaser} dangerouslySetInnerHTML={{ __html: teaser }} />}
      {modifiedcontent && <div className={contentClassNames} dangerouslySetInnerHTML={{ __html: modifiedcontent }} />}
    </article>
  );
}

export default ContentBlock;
